import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";

const carouselElements = document.querySelectorAll(".Carousel");
const slidesPerView = 4;
// Check if any ImageBars appear on page.
if (carouselElements.length) {
  // For each of them, generate a keen slider.
  carouselElements.forEach((carouselElement) => {
    const slider = new KeenSlider(carouselElement, {
      rtl: false,
      slidesPerView: slidesPerView,
      spacing: 20,
      loop: true,
      breakpoints: {
        "(max-width: 1280px)": {
          slidesPerView: 3,
        },
        "(max-width: 1024px)": {
          slidesPerView: 2,
        },
        "(max-width: 768px)": {
          slidesPerView: 1,
        },
      },
      created: function (instance) {
        document
          .getElementById("arrow-left")
          .addEventListener("click", function () {
            instance.moveToSlide(slider.details().absoluteSlide - 4);
          });

        document
          .getElementById("arrow-right")
          .addEventListener("click", function () {
            instance.moveToSlide(slider.details().absoluteSlide + 4);
          });
        const dots_wrapper = document.getElementById("dots");
        const slides = document.querySelectorAll(".Carousel__slide");

        slides.forEach(function (t, idx) {
          if (idx % (Math.round(slides.length / slidesPerView) + 1) === 0) {
            const dot = document.createElement("button");
            dot.classList.add("dot");
            dots_wrapper.appendChild(dot);
            dot.addEventListener("click", function () {
              instance.moveToSlide(idx);
            });
          }
        });
        updateClasses(instance);
      },
      slideChanged(instance) {
        updateClasses(instance);
      },
    });
  });
}

function updateClasses(instance) {
  const slide = instance.details().relativeSlide;
  const arrowLeft = document.getElementById("arrow-left");
  const arrowRight = document.getElementById("arrow-right");
  slide === 0
    ? arrowLeft.classList.add("arrow--disabled")
    : arrowLeft.classList.remove("arrow--disabled");
  slide === instance.details().size - 1
    ? arrowRight.classList.add("arrow--disabled")
    : arrowRight.classList.remove("arrow--disabled");
  const dots = document.querySelectorAll(".dot");
  dots.forEach(function (dot, idx) {
    idx === slide / slidesPerView
      ? dot.classList.add("dot--active")
      : dot.classList.remove("dot--active");
  });
}
