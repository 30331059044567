const blogSections = document.querySelectorAll(".js-blog-section");

// check if there is a blogSection on the page
if (blogSections && blogSections.length) {
  // for each blog section do the following
  blogSections.forEach((blogSection) => {
    const select = blogSection.querySelector("#types");
    const posts = blogSection.querySelectorAll(".BlogListItem");

    select.addEventListener("change", (ev) => {
      let value = ev.target.value;

      posts.forEach((post) => {
        let valueWitNoSpace = value.replace(/\s+/g, "");

        if (post.classList.contains(valueWitNoSpace) || value === "") {
          post.style.display = "block";
        } else {
          post.style.display = "none";
        }
      });
    });
  });
}
