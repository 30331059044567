import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";

const sliderElements = document.querySelectorAll(".Slider");

// Check if any Sliders appear on page.
if (sliderElements.length) {
  // For each of them, generate a keen slider.
  sliderElements.forEach((sliderElement) => {
    var interval = 0;
    function autoplay(run) {
      clearInterval(interval);
      interval = setInterval(() => {
        if (run && slider) {
          slider.next();
        }
      }, 5000);
    }
    const slider = new KeenSlider(sliderElement, {
      rtl: false,
      loop: true,
      slidesPerView: 1,
      created: function (instance) {
        document
          .getElementById("arrow-left")
          .addEventListener("click", function () {
            instance.prev();
          });

        document
          .getElementById("arrow-right")
          .addEventListener("click", function () {
            instance.next();
          });
        const dots_wrapper = document.getElementById("dots");
        const slides = document.querySelectorAll(".Slider__slide");

        slides.forEach(function (t, idx) {
          const dot = document.createElement("button");
          dot.classList.add("dot");
          dots_wrapper.appendChild(dot);
          dot.addEventListener("click", function () {
            instance.moveToSlide(idx);
          });
        });
        updateClasses(instance);
      },
      slideChanged(instance) {
        updateClasses(instance);
      },
    });
    sliderElement.addEventListener("mouseover", () => {
      autoplay(false);
    });
    sliderElement.addEventListener("mouseout", () => {
      autoplay(true);
    });
    autoplay(true);
  });
}

function updateClasses(instance) {
  const slide = instance.details().relativeSlide;
  const arrowLeft = document.getElementById("arrow-left");
  const arrowRight = document.getElementById("arrow-right");
  slide === 0
    ? arrowLeft.classList.add("arrow--disabled")
    : arrowLeft.classList.remove("arrow--disabled");
  slide === instance.details().size - 1
    ? arrowRight.classList.add("arrow--disabled")
    : arrowRight.classList.remove("arrow--disabled");
  const dots = document.querySelectorAll(".dot");
  dots.forEach(function (dot, idx) {
    idx === slide
      ? dot.classList.add("dot--active")
      : dot.classList.remove("dot--active");
  });
}
