// get the anchor link buttons
const anchor = document.getElementById("anchor");
const closeBtn = document.getElementById("closeBtn");
const overlay = document.getElementById("overlay");

if (anchor) {
  const idsArr = [anchor, closeBtn, overlay];

  idsArr.forEach((id) => {
    id.addEventListener("click", () => {
      setTimeout(() => {
        // call removeHash function after set timeout
        removeHash();
      }, 5); // 5 millisecond timeout in this case
    });
  });

  function removeHash() {
    history.replaceState(
      "",
      document.title,
      window.location.origin + window.location.pathname + window.location.search
    );
  }
}
